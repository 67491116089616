// tslint:disable-next-line:class-name
export class alert {
    type: alertType;
    message: string;
    remove: boolean;
}
export enum alertType {
    Success,
    Error,
    Info,
    Warning
}
