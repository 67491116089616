<!-- <h2 mat-dialog-title>Product settings</h2> -->

<mat-card>
  <mat-card-header class="mb-3">
    <mat-card-title><span class="material-icons">
        hearing
      </span>{{Title}}</mat-card-title>

  </mat-card-header>
  <mat-card-content>
    <section class="d-flex">
      <mat-form-field appearance="fill" class="col-12 mp-0">
        <mat-label>Headline</mat-label>
        <input matInput type="text" required [(ngModel)]="marketUpdateViewModel.marketHeadding" />
      </mat-form-field>
    </section>
    <section class="d-flex-center">
      <div class="box">
        <div class="js--image-preview"></div>
        <div class="upload-options">
          <label>
            <input type="file" class="image-upload" accept="image/*" />
          </label>
        </div>
      </div>
    </section>
    <section class="d-flex">
      <div class="col-12">
        <kendo-editor [(ngModel)]="marketUpdateViewModel.marketTopic" style="height: 370px;"></kendo-editor>
      </div>


    </section>

  </mat-card-content>
  <mat-card-actions align="end">
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-button [mat-dialog-close]="true" cdkFocusInitial (click)="submit()">Save</button>
  </mat-card-actions>
</mat-card>
