<footer class="footer ">
    <div class="container-fluid">
        <!-- <nav class="pull-left">
            <ul>
                <li>
                    <a href="#">
                       Omni Order
                    </a>
                </li>
                <li>
                    <a href="#">
                        About Us
                    </a>
                </li>
                <li>
                    <a href="#">
                        Blog
                    </a>
                </li>
                <li>
                    <a href="#">
                        Licenses
                    </a>
                </li>
            </ul>
        </nav> -->
        <div class="copyright pull-right">
            &copy;
            {{test | date: 'yyyy'}},
            <a href="#" target="_blank" class="primary">Optomni Pty Ltd </a>.
        </div>
    </div>
</footer>
