import { Injectable } from '@angular/core';
import { UtilsService } from 'app/shared/services/utils.service';

/** Mock client-side authentication/authorization service */
@Injectable()
export class AuthService {

  constructor(private util: UtilsService) { }
  getAuthorizationToken() {

    var userInfo = this.util.GetUserInfofromSession();
    if (userInfo != null && userInfo != undefined) {
      return 'Bearer ' + userInfo.idTokenClaims.nonce;
    }
    return '';
  }
}
