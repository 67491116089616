import { Component } from '@angular/core';
import { MessageService } from 'app/shared/services/message.service';
import { alert, alertType } from 'app/shared/models/alert';

@Component({
    moduleId: module.id,
    // tslint:disable-next-line:component-selector
    selector: 'alert',
    templateUrl: 'alert.component.html'
})

export class AlertComponent {
    alerts: alert[] = [];
    lastAlert: alert;

    constructor(private messageService: MessageService) { }

    // tslint:disable-next-line:use-life-cycle-interface
    ngOnInit() {
        this.messageService.getAlert().subscribe((_alert: alert) => {
            let lastAlert = _alert;
            if (!_alert) {
                // clear alerts when an empty alert is received
                this.alerts = [];
                return;
            }
            if (this.alerts.length === 0) {
                this.alerts.push(_alert);
                // setTimeout(() => this.alerts = this.alerts.filter(x => x !== _alert), 3000);
            }
        });

    }
    removeAlert(_alert: alert) {
        this.alerts = this.alerts.filter(x => x !== _alert);
    }
    remove(_alert: alert) {

        // setTimeout(function () {
        //     const index: number = this.alerts.indexOf(_alert);
        //     if (index !== -1) {
        //         this.alerts.splice(index, 1);
        //     }
        // }, 2000);
    }
    cssClass(_alert: alert) {
        if (!_alert) {
            return;
        }

        // return css class based on alert type
        switch (_alert.type) {
            case alertType.Success:
                return 'alert alert-success';
            case alertType.Error:
                return 'alert alert-danger';
            case alertType.Info:
                return 'alert alert-info';
            case alertType.Warning:
                return 'alert alert-warning';
        }
    }
}
