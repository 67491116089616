// tslint:disable-next-line:import-blacklist
import { Observable } from 'rxjs/Rx';
import 'rxjs/add/operator/share';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { of } from 'rxjs';
import { MessageService } from './message.service';
import { SellerViewModel } from '../models/seller';


@Injectable()
export class UtilsService {
    constructor(private messageservice: MessageService) { }

    getComparableStr(str: string) {
        if (!str) { return ''; }
        return str.replace(' ', '').toLowerCase();
    }

    getDateWithoutTimeZone(date: Date) {
        const userTimezoneOffset = date.getTimezoneOffset() * 60000;
        return new Date(date.getTime() - userTimezoneOffset);
    }
    logerror<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {

            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // TODO: better job of transforming error for user consumption
            this.log(`${operation} failed: ${error.message}`);

            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }

    private log(message: string) {
        this.messageservice.error(`HeroService: ${message}`);
    }
    handleError(error: any) {
        let applicationError = error.headers.get('Application-Error');
        let serverError = error.json();
        let modelStateErrors = '';
        if (!serverError.type) {
            for (let key in serverError) {
                if (serverError[key]) {
                    if (key === 'ModelState') {
                        // tslint:disable-next-line:forin
                        for (let validKey in serverError[key]) {
                            modelStateErrors += serverError[key][validKey] + '\n';
                        }
                    } else {
                        modelStateErrors += serverError[key] + '\n';
                    }
                }
            }
        }

        modelStateErrors = modelStateErrors = '' ? null : modelStateErrors;

        return Observable.throw(applicationError || modelStateErrors || 'Server error');
    }

    extractHttpData<T>(res: Response) {
        if (res.status < 200 || res.status >= 300) {
            throw new Error('Error Occured: ' + res.status);
        }
        const body = res.json ? res.json() : null;
        return <T>(body || {});
    }

    changeBodyFormat(body: object, prefix?: string): string {
        if (body) {
            const types = Reflect.ownKeys(body);
            let newFormat = '';
            types.forEach(x => {
                let paramKey = prefix ? `${prefix}[${x.toString()}]` : x.toString();
                if ((Array.isArray(body[x]))) {

                    body[x].forEach((el: string) => {
                        newFormat += paramKey + '=' + el.toString() + '&';
                    });

                } else if (typeof body[x] === 'object') {
                    newFormat += this.changeBodyFormat(body[x], paramKey) + '&';
                } else {
                    newFormat += paramKey + '=' + (body[x] !== undefined ? body[x].toString() : '') + '&';
                }
            });

            return newFormat.slice(0, -1);
        }
    }

    SetUserEmailToSession(email: string) {
        sessionStorage.setItem('UserEmail', email);
    }

    GetUserInfofromSession() {
        if (sessionStorage.getItem('userObj')) {
            let EncodedInfo = sessionStorage.getItem('userObj');
            let _info = JSON.parse(EncodedInfo);
            return _info;
        } else { return null; }
    }

    GetCurrentSellerfromSession(): SellerViewModel {

        let seller: SellerViewModel = this.getObjectFromSession("seller");
        return seller;
    }

    RemoveObjectfromSession(key: string) {
        if (sessionStorage.getItem(key)) {
            sessionStorage.removeItem(key);
        }
    }

    AddObjectToSession(key: string, value: any) {
        let stringfied = JSON.stringify(value);
        sessionStorage.setItem(key, stringfied);
    }

    getObjectFromSession(key: string) {
        if (sessionStorage.getItem(key)) {
            let EncodedInfo = sessionStorage.getItem(key);
            let _info = JSON.parse(EncodedInfo);
            return _info;
        } else { return null; }
    }

    ExportToExcell(data: any[], SelectedColumn: any[] = null) {

        let csv = this.convertArrayOfObjectsToCSV(data, SelectedColumn);
        if (csv == null) {
            return;
        }
        let filename = 'export.csv';
        let link = document.createElement('a');
        link.setAttribute('href', 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURI(csv));
        link.setAttribute('download', filename);
        link.click();
    }

    convertArrayOfObjectsToCSV(data: any[], SelectedColumn: any[] = null) {

        try {
            if (data == null || !data.length) {
                return null;
            }
            let picked: any[];
            let result = '';
            let columnDelimiter = ',';
            let lineDelimiter = '\n';
            if (SelectedColumn !== null) {

                data.forEach(item => {
                    picked.push(_.pick(item, ['inspNo', 'establishmentNameEn', 'establishmentNameLicenseNo', 'inspectionType', 'inspectorName', 'createdBy', 'schedualDate', 'startDate', 'status']));
                });
            } else {
                picked = data;
            }
            let keys = Object.keys(picked[0]);
            result += keys.join(columnDelimiter);
            result += lineDelimiter;
            picked.forEach(item => {
                let ctr = 0;
                keys.forEach(key => {
                    if (ctr > 0) {
                        result += columnDelimiter;
                    }
                    result += String(item[key]).replace(/\,/g, ""); // item[key];
                    ctr++;
                });
                result += lineDelimiter;
            });
            return result;
        } catch (error) {
            return null;
        }
    }

    SetUserGroupToSession(group: string) {
        sessionStorage.setItem('UserGroup', group);
    }

    GetUserGroupfromSession(): string {
        if (sessionStorage.getItem('UserGroup')) {
            let UserGroup = sessionStorage.getItem('UserGroup');
            return UserGroup;
        } else { return null; }
    }

}
export function getCookie(name: string): string {
    const nameLenPlus = (name.length + 1);
    return document.cookie
        .split(';')
        .map(c => c.trim())
        .filter(cookie => {
            return cookie.substring(0, nameLenPlus) === `${name}=`;
        })
        .map(cookie => {
            return decodeURIComponent(cookie.substring(nameLenPlus));
        })[0] || null;
}
export function getBaseLocation() {
    // return environment.baseHref;
    // let paths: string[] = location.pathname.split('/').splice(1, 1);
    // let basePath: string = (paths && paths[0]) || 'pages/home.aspx'; // Default: my-account
    // return '/' + basePath;
}
