import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { alert, alertType } from '../models/alert';

@Injectable({
    providedIn: 'root'
})
export class MessageService {
    private subject = new Subject<alert>();
    private keepAfterRouteChange = false;

    constructor(private router: Router) {
        // clear alert messages on route change unless 'keepAfterRouteChange' flag is true
        router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                if (this.keepAfterRouteChange) {
                    // only keep for a single route change
                    this.keepAfterRouteChange = false;
                } else {
                    // clear alert messages
                    this.clear();
                }
            }
        });
    }

    getAlert(): Observable<any> {
        return this.subject.asObservable();
    }

    success(message: string, Temporary: boolean = true, keepAfterRouteChange: boolean = false) {
        this.alert(alertType.Success, message, keepAfterRouteChange);
    }

    error(message: string, Temporary: boolean = false, keepAfterRouteChange: boolean = false) {
        this.alert(alertType.Error, message, keepAfterRouteChange);
    }

    info(message: string, Temporary: boolean = true, keepAfterRouteChange: boolean = false) {
        this.alert(alertType.Info, message, keepAfterRouteChange);
    }

    warn(message: string, Temporary: boolean = true, keepAfterRouteChange: boolean = false) {
        this.alert(alertType.Warning, message, keepAfterRouteChange);
    }

    alert(type: alertType, message: string, Temporary: boolean = true, keepAfterRouteChange: boolean = false) {
        this.keepAfterRouteChange = keepAfterRouteChange;
        this.subject.next(<alert>{ type: type, message: message, remove: Temporary });
    }

    clear() {
        // clear alerts
        this.subject.next();
    }
}
